@import '~styles/index.less';

.synri-stat-block-value {
  background-color: @gray-300;
  border-radius: @borderRadius[md];
  color: @gray-900;
  font-size: @fontSizes[xl];
  line-height: 1;
  /* I need to accomodate a border issue so that these stat blocks line up with other items */
  padding: calc(@spacings[xxsm] - 0.5px) @spacings[sm];
}

.synri-stat-block-label {
  color: @gray-800;
  font-size: @fontSizes[md];
  font-weight: @fontWeights[regular];
}
