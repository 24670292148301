@import '~styles/index.less';

.scheduler-input {
  .ant-input {
    margin-bottom: 0;
  }

  .synri-error-text {
    color: @red-500;
  }

  .synri-help-text {
    line-height: @lineHeights[regular];
  }

  .inline-label {
    &.lower {
      text-transform: lowercase;
    }
  }
}
