@import '~styles/index.less';

.synri-merge-options-container {
  .synri-h-stack > div:not(.synri-spacer),
  span {
    flex: 1;
  }

  .synri-select {
    width: 100%;
  }

  .synri-merge-row-borders {
    padding: @spacings[xxs] 0;
    border-width: 1px 0;
    border-color: @gray-300;
    border-style: solid;
  }
}
