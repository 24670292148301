@import '~styles/index.less';

.syncari-ag-table-pagination-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  padding: @spacings[sm] 0;

  &--simple {
    padding-bottom: 0;
    margin-right: -@spacings[md];

    .ant-btn-link,
    .ant-btn-link:hover,
    .ant-btn-link:focus {
      color: @gray-800;
    }

    .ant-btn-link[disabled] {
      color: @gray-500;
    }
  }

  &__goto-page {
    text-align: center;
  }
}

.syncari-ag-table-pagination-button {
  display: flex;

  height: @fontSizes[lg];
  width: @fontSizes[lg];

  padding: @spacings[xs] @spacings[md];

  svg {
    height: 100%;
    width: 100%;
  }
}

.total-records {
  color: black;
}
