@import 'styles';

.filter-button {
  display: inline-flex;
  align-items: center;
  padding-left: $spacings-sm;

  svg {
    height: 24px;
    padding-right: $spacings-xxxs;
  }

  &__active-indicator {
    width: 10px;
    height: 10px;
    background-color: $syncari-blue;
    border-radius: 100%;
    margin-left: $spacings-xs !important;
  }
}
