@import '~styles/index.less';

.syncari-boolean-cell {
  color: @gray-700;
  height: @fontSizes[sm];
  width: auto;
  margin: auto 0;
}

/** Spring SDS */
