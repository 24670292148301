@import '~styles/index.less';

.vizer {
  height: 100%;
  width: 100%;
  height: 100%;

  &__label {
    color: @gray-800;
    margin-top: -@spacings[xxs];
    margin-bottom: @spacings[xs];
  }
}
