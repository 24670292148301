@import '~styles/index.less';

.confirmation-container {
  margin-top: @spacings[xl];

  .success-check-icon {
    display: inline-block;
    height: 64px;
    width: 64px;
    color: @syncari-blue;
  }

  .success-text {
    color: @syncari-blue;
  }
}
