@import '~styles/index.less';

.score-cell {
  border-radius: @borderRadius[full];
  padding: 0 @spacings[sm];
  line-height: @fontSizes[xxl];

  &.small {
    line-height: @lineHeights[regular];
    padding: 0 @spacings[xs];
  }
}
