@import 'styles';

.entity-config-step {
  &__testing-container {
    position: relative;
  }
  &__schema-testing-button {
    text-align: right;
  }
}
