@import '~styles/index.less';

.date-time-selector {
  display: flex;

  .date-prefix {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-width: @spacings[3xl];
    background-color: @gray-300;
    border-radius: @borderRadius[md] 0 0 @borderRadius[md];
    border: 1px solid @gray-400;
  }

  .date-prefix + .date-picker .ant-calendar-picker-input {
    border-radius: 0 @borderRadius[md] @borderRadius[md] 0;
    margin-left: -1px;
  }

  .time-picker {
    margin-left: @spacings[xs];
    width: initial;
  }

  &.has-error {
    .date-prefix,
    .date-picker,
    .time-picker {
      border-color: @red-500;
    }
  }
}
