@import '~styles/index.less';

.synri-tree-skeleton-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.synri-tree-skeleton-item-container {
  display: flex;

  &.synri-tree-skeleton-item-container-hidden {
    display: none;
  }
}

.synri-tree-skeleton-expansion-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.synri-tree-skeleton-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: @spacings[md];
  height: @spacings[md];
  border-radius: 2px;
  background-color: @gray-750;

  &.timeline {
    border-radius: 8px;
    background-color: @blue-750;
    width: 16px;
    height: 16px;
  }
}

.synri-tree-skeleton-expand-border {
  border-color: @gray-400;
  flex-grow: 1;
  border-left-style: solid;
  border-width: 0px;

  &.timeline {
    border-color: @blue-400;
  }
}
.synri-tree-skeleton-expand-border-show {
  border-left-width: 1px;

  &.timeline {
    border-left-width: 2px;
  }
}
.synri-tree-skeleton-body-bottom-border {
  border-color: @gray-400;
  flex-grow: 1;
  border-bottom-style: solid;
  margin: @spacings[xxs] 0px @spacings[xs];
  border-bottom-width: 1px;
}

.synri-tree-skeleton-label {
  padding: @spacings[xs] @spacings[sm];
  display: flex;
  line-height: 1.8;
  flex: 1;
}

.synri-tree-skeleton-label-border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: @gray-400;
}

.synri-tree-skeleton-label-set-height {
  line-height: 1.8;
}

.synri-tree-skeleton-expand-button {
  padding: @spacings[xxxs] @spacings[xs];
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.synri-tree-skeleton-expand-icon-text {
  color: @white;
  line-height: 0.6;
}
