@import '~styles/index.less';

.syncari-id-mapping-list {
  margin: 0;
  padding: 0;
}

.syncari-id-mapping-list-item {
  list-style-type: none;

  .id-mapping-synapse-name {
    font-weight: @fontWeights[medium];
  }
  .id-mapping-external-id {
    font-family: @font-family-mono;
  }
}

.id-mapping-info {
  color: @blue-400;
  margin-left: @spacings[xs];
}
