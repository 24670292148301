@import '~styles/index.less';

.synri-field-group {
  .synri-label-tooltip-icon {
    margin-left: @spacings[xs];
  }

  .synri-help-text {
    color: @gray-700;
    font-size: @fontSizes[sm];
  }
}
