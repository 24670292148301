.synri-stack {
  &.stack-fill-parent {
    flex: 1;
    display: flex;
    flex-flow: column;
  }

  &.stack-scroll-overflow {
    overflow-y: auto;
  }

  &.stack-center-content {
    text-align: center;
  }
}

// These classes are set by the skullConfig from the backend
.synri-skull-stack-container-sm {
  width: 100%;
  max-width: 800px;
}

.synri-skull-stack-container-md {
  width: 100%;
}

.synri-skull-stack-container-full-center {
  width: 100%;
  justify-content: center;
}

.synri-skull-stack-container-lg {
  width: 100%;
  max-width: 1600px;
}
