@import 'styles';

$background-transition: background-color 0.2s cubic-bezier(0.12, 0, 0.39, 0);

.white-space-wrapper {
  display: flex;
  flex-wrap: wrap;

  &--white-space-character {
    width: 10px;
    height: 18px;
    border-radius: 1px;
    background-color: $gray-300;
    margin: 0 1px;
    color: $gray-700;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 0.8em;
    transition: $background-transition;

    &:hover {
      background-color: $gray-500;
    }
  }
}
