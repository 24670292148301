@import '~styles/index.less';

.syncari-text {
  /* line heights */
  each(@lineHeights, {
    &.line-height-@{key} {
      line-height: @value;
    }
  })

  /* font sizes */
  each(@fontSizes, {
    &.font-size-@{key} {
      font-size: @value;
    }
  })


  /* font weights */
  each(@fontWeights, {
    &.font-weight-@{key} {
      font-weight: @value;
    }
  })

  &.color-black {
    color: @black;
  }
  &.color-white {
    color: @white;
  }
  &.color-light-gray {
    color: @light-gray;
  }
  &.color-gray-100 {
    color: @gray-100;
  }
  &.color-gray-200 {
    color: @gray-200;
  }
  &.color-gray-300 {
    color: @gray-300;
  }
  &.color-gray-400 {
    color: @gray-400;
  }
  &.color-gray-500 {
    color: @gray-500;
  }
  &.color-gray-700 {
    color: @gray-700;
  }
  &.color-gray-750 {
    color: @gray-750;
  }
  &.color-gray-800 {
    color: @gray-800;
  }
  &.color-gray-850 {
    color: @gray-850;
  }
  &.color-gray-900 {
    color: @gray-900;
  }
  &.color-gray-1000 {
    color: @gray-1000;
  }
  &.color-red-100 {
    color: @red-100;
  }
  &.color-red-200 {
    color: @red-200;
  }
  &.color-red-300 {
    color: @red-300;
  }
  &.color-red-500 {
    color: @red-500;
  }
  &.color-orange-700 {
    color: @orange-700;
  }
  &.color-blue-600 {
    color: @blue-600;
  }
  &.color-syncari-blue {
    color: @syncari-blue;
  }

  &.no-wrap {
    white-space: nowrap;
  }

  &.break-all {
    word-break: break-all;
  }

  &.break-word {
    overflow-wrap: break-word;
  }
}
