@import '~styles/index.less';

.synri-h-stack {
  display: flex;
  flex-flow: row;
  flex-grow: 0;
  flex-shrink: 0;

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-baseline {
    align-items: baseline;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-evenly {
    justify-content: space-evenly;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.flex-shrink {
    flex-shrink: 1;
  }

  &.flex-grow {
    flex-grow: 1;
  }
}
